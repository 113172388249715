/*
*   primereact overwrites
*/

.p-button:focus, .p-tabview-nav-link {
    box-shadow: none !important;
}




/*
*   Media Dependent
*/

//Phones
@media (max-width: 480px) {
    body {
        font-size: 16px;
    }

    .content {
        width: 80%;
    }

    .font-xxl {
        font-size: 40px;
    }

    .font-xl {
        font-size: 30px;
    }

    .font-l {
        font-size: 25px;
    }

    .pc {
        display: none;
    }

    .navbar {
        justify-content: center !important;
    }
}

//Tablets
@media (min-width: 481px) and (max-width: 768px) {
    body {
        font-size: 17px;
    }

    .content {
        width: 75%;
    }

    .font-xxl {
        font-size: 50px;
    }

    .font-xl {
        font-size: 35px;
    }

    .font-l {
        font-size: 27px;
    }

    .pc {
        display: none;
    }
}

//Laptops
@media (min-width: 769px) and (max-width: 1279px) {
    body {
        font-size: 20px;
    }

    .content {
        width: 80%;
    }

    .split {
        display: flex;
        flex-direction: row;
        gap: 1rem;
    
        & > div {
            width: 50%;
            display: flex;
            align-items: center;
        }
    }

    .font-xxl {
        font-size: 75px;
    }

    .font-xl {
        font-size: 40px;
    }

    .font-l {
        font-size: 29px;
    }

    .mobile {
        display: none;
    }
}

//Monitors
@media (min-width: 1280px) {
    body {
        font-size: 20px;
    }

    .content {
        width: 70%;
    }

    .split {
        display: flex;
        flex-direction: row;
        gap: 1rem;
    
        & > div {
            width: 50%;
            display: flex;
            align-items: center;
        }
    }
    
    .font-xxl {
        font-size: 115px;
    }

    .font-xl {
        font-size: 50px;
    }

    .font-l {
        font-size: 32px;
    }

    .mobile {
        display: none;
    }
}





/*
*   General Settings / reused components
*/

.primary {
    color: var(--primary-color);
}

a {
    text-decoration: none;
    color: var(--primary-color);
}

body {
    font-family: var(--font-family) !important;
    margin: 0;
    color: var(--text-color-secondary);
    background-color: var(--surface-100);
}

#fullpage {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;

    &-content {
        display: flex;
        flex: 1 0 auto;
        align-items: stretch;
        overflow: hidden;
    }
}

.fullwidth {
    width: 100%;
}

h1, h2, h3, h4, h5, .hl {
    color: var(--text-color);
}

.icon {
    color: var(--primary-color);
}

.sidebar {
    font-size: 1.1rem;
    list-style-type: none;

    &-hidden {
        left: 0 !important;
        transform: translateX(-100%);
    }

    &-category {
        padding: 0.3rem 0;

        &-label {
            margin: .2rem 0;
            font-weight: 600;
            text-transform: uppercase;
            color: var(--text-color);
        }
    }

    &-item {

        & a {
            color: var(--text-color-secondary);
            cursor: pointer;
            text-decoration: none;
            transition: color .2s;
            border-radius: 8px;
            padding: .3rem 1rem;
            margin: .1rem 0;
            transition: background-color .15s;
            display: inline-flex;
            gap: 8px;
            align-items: center;
            width: 100%;

            &:hover {
                background-color: var(--surface-100);
            }

            &:focus {
                outline: 0 none;
                outline-offset: 0;
                transition: box-shadow .2s;
                box-shadow: inset var(--focus-ring);
            }
        }
    }
}

//Fullview elements
.header {
    width: 100vw;
    height: 100vh;
    margin: 0;
}

.right-side-button {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.content {
    margin: 2.5rem auto;
    max-width: 90%;
}

.divider {
    width: 95%;
    margin: .75rem auto;
    height: 0px;
    border: rgba(0,0,0,0.5) solid 1px;
}

.icon-text {
    svg, span {
        vertical-align: middle;
    }
}
  
.shimmer {
    animation-duration: 4s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
    background: #ddd;
    background: linear-gradient(to right, rgb(238, 238, 238) 10%, rgb(220, 220, 220) 20%,  rgb(238, 238, 238) 30%);
    background-size: 3000px;

    &-text {
        border-radius: 12px;
    }
}

.headline {
    text-align: center;
}

.box {
    margin: 0.75rem 0;
    padding: 2rem;
    background-color: var(--surface-card);
    border-radius: var(--border-radius);
    width: 100%;
}

.box-elements {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    gap: 4rem;

    & > div {
        width: 150px;
        word-break: keep-all;
    }
}

.left {
    text-align: left;
}

.centered {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.right {
    text-align: right;
}

img {
    max-width: 100%;
    max-height: 100%;
}

.full-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -100% 0;
    }
    100% {
        background-position: 100% 0;
    }
}

@keyframes shimmer {
    0% {
        background-position: -3000px 0;
    }
    100% {
        background-position: 3000px 0;
    }
}




/*
*   Forms
*/

.field {
    max-width: 100%;
    width: 600px;
    margin: 25px auto;
    text-align: left;

    & .p-error {
        display: block;
        padding: 0.25rem 0.5rem;
    }

    &-input input, &-input textarea, &-input > div {
        width: 100%;
    }
}

.checkbox-label {
    margin-left: 20px;
}

.success-message {
    margin: 20px;

    &-icon {
        margin: 20px;
        font-size: 5em;
        color: var(--green-500);
    }
}




/*
*   Single usage
*/
//Navbar
html:not([data-scroll='0']) {
    .navbar {
        background-color: var(--surface-card);
        transition: .2s ease;
    }
}

.navbar {
    width: 100%;
    position: fixed;
    z-index: 1;
    display: flex;
    justify-content: space-between;

    &-burger {
        display: inline-flex;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
        color: var(--text-color);
        cursor: pointer;
    }

    & > div {
        display: flex;
        gap: .25rem 2.5rem;
        justify-content: flex-end;
        padding: .75rem 2rem;
        top: 0;
        flex-wrap: wrap;

        & a {
            color: var(--text-color);
            display: inline-flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            padding: .25rem .5rem;
            border-bottom: 2px solid transparent;

            &:hover {
                border-bottom: 2px solid var(--primary-color);
                transition: .1s ease;
            }
        }
    }
}

//Parallax header
.header {
    &-gradient {
        &-background {
            background: radial-gradient(circle, rgba(0,0,0,0.3) 15%, rgba(0,0,0,0) 80%);
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            position: absolute;
        }

        &-full {
            background: rgb(0,0,0);
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            position: absolute;
        }
    }

    &-textbox {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;

        &-headline {
            text-align: center;
            color: #FFF;
        }
    }

    &-background {
        background-repeat: no-repeat;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}

.profile {
    text-align: center;
    width: 100%;

    & img {
        border-radius: 100%;
        width: 350px;
        max-width: 80%;
    }
}

#footer {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    color: var(--surface-600);
    gap: 1.25rem;
    flex: 0 0 auto;
    
    & > div {
        display: flex;
        justify-content: flex-start;
        gap: 1.25rem;
        flex-wrap: wrap;
    }

    & a {
        color: var(--surface-600);

        &:hover {
            color: var(--primary-600);
        }
    }
}

.login {
    & .p-tabview-nav {
        justify-content: center;
    }
}

#forum-layout {
    width: 100%;
    margin-left: 300px;
    margin-top: 7rem;
    position: relative;
    transition: margin-left .2s;
}

.forum-layout-full {
    margin-left: 0px !important;
}

#sidebar {
    position: fixed;
    width: 300px;
    height: calc(100vh - 8rem);
    z-index: 999;
    overflow-y: auto;
    user-select: none;
    top: 4rem;
    left: 2rem;
    transition: left .2s,-webkit-transform .2s;
    transition: transform .2s,left .2s;
    transition: transform .2s,left .2s,-webkit-transform .2s;
    background-color: var(--surface-overlay);
    border-radius: 7px;
    padding: 1.5rem;
    box-shadow: 0 1px 1px rgba(0,0,0,.02),0 0 1px rgba(0,0,0,.05),0 1px 1px rgba(0,0,0,.08);

    & ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
}